<template>
  <div class="about">
    <div class="settingwarp">
      <header>
        <div class="g-header" style="background: rgb(14, 21, 48)">
          <div class="left" @click="changego">
            <van-icon name="arrow-left" color="#fff" size="20px" />
          </div>
          <div class="middle fs-18">{{ $t('guanyuwomen') }}</div>
          <div class="right">
            <div class="bg-icon bg-icon-setting"></div>
          </div>
        </div>
        <div  class="blockHeight"></div>
        <div  class="g-content">
          <div class="logo-box">
            <img src="@/assets/image/logo.png" class="logo" alt="" />
            <!-- <p class="version">{{ $t('dangqianbanben') }}：23.11.28</p> -->
            <p class="copyright">{{ $t('banquansuoyou') }}©中金公司</p>
          </div>
          <ul :style="{ 'max-height': this.Height + 'px' }" style="overflow-y:scroll;" class="list-box">

            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V2.1<br>2024-06-15</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>

            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V2.0<br>2023-8-20</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(1)">
              <div class="list-text">《隐私保护指引》更新 <br>2023-3-29</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>

            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V1.8<br>2022-10-20</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V1.7<br>2022-05-01</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V1.6<br>2022-01-01</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V1.5<br>2021-09-01</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V1.4<br>2021-06-01</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V1.3<br>2021-02-11</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V1.2<br>2020-09-01</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V1.1<br>2020-02-20</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V1.0<br>2019-09-01</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V0.9<br>2019-02-01</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V0.8<br>2018-06-01</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V0.7<br>2017-05-01</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V0.6<br>2016-10-21</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V0.5<br>2015-05-01</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V0.4<br>2015-01-01</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V0.3<br>2014-07-01</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V0.2<br>2014-03-01</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
            <li class="list" @click="changestting(2)">
              <div class="list-text">版本更新:V0.1<br>2013-08-08</div>
              <img src="@/assets/image/you.png" class="arrow" alt="" />
            </li>
           
          </ul>
          
        </div>
       
      </header>
    </div>
  </div>
</template>
<script>
export default {
  name: "About",
  components: {},
  data() {
    return {
      Height:""
    };
  },
  methods: {
    changego() {
      this.$router.go(-1);
    },
    changestting(type) {
      this.$router.push({
        path: "/aboutDetail",
        query: {
          type: type,
        },
      });
    },
    mounted() {
    this.Height = document.documentElement.clientHeight;
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight;
    };
  },
  },
};
</script>
<style lang="less" scoped>
.g-content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: scroll;
  text-align: center;
  color: #a0a0a0;
}

.logo-box {
  margin-top: 0.1rem;
}

.logo {
  width: 3.8rem;
  height: 3.8rem;
}

.version {
  margin-top: 0.4rem;
  font-size: 0.4rem;
}

.copyright {
  position: absolute;
  width: 100%;
  bottom: 0.4rem;
  font-size: 0.4rem;
}

.list-box {
  border-radius: 0.1rem;
  background: #1a243f;
  margin: 0 0.2rem;
  padding: 0 0.24rem 0 0.3rem;
}

.list {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 0 0.35rem;
  border-bottom: 1px solid #3d5179;
}

.list-text {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: left;
  font-size: 0.4rem;
}

.arrow {
  width: 0.2rem;
  height: 0.4rem;
}

.p-account .list-box .list:last-child {
  border-bottom: none;
}
</style>